export const removeTagAttributes = (
  content: string,
  tagName: string
): string => {
  const tagStartRegex = new RegExp(`(<${tagName})\\s+[^>]*(>)`, 'g')
  const tagEndRegex = new RegExp(`(</${tagName}>)`, 'g')
  return content.replace(tagStartRegex, '').replace(tagEndRegex, '')
}

const getCookie = (name: string): string | undefined => {
  if (typeof document === 'undefined') {
    return undefined
  }
  const cookieString = decodeURIComponent(document.cookie)
  const cookies = cookieString.split(';')
  for (const cookie of cookies) {
    const _cookie = cookie.replace(' ', '')
    const _name = _cookie.split('=')[0]
    if (_name === name) {
      return cookie.split('=')[1]
    }
  }
}

const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms))

const isPardotTracking = () => {
  const scripts = Array.from(document.getElementsByTagName('script'))
  // NOTE: Pardotのトラッキングコードは"window.load"イベント時に発火し変数定義されるので、スクリプト自体に変数名があるかどうかで判定するようにしている。
  const isTracking = scripts.some(
    (script) =>
      script.innerHTML.includes('piAId') &&
      script.innerHTML.includes('piCId') &&
      script.innerHTML.includes('pd.js')
  )
  return isTracking
}

const isMarketoTracking = () => {
  const src = '//munchkin.marketo.net/munchkin.js'
  const scripts = Array.from(document.getElementsByTagName('script'))
  const isTracking = scripts.some(
    (script) => script.getAttribute('src') === src
  )
  return isTracking
}

const getPardotVisitorId = (): string => {
  // @ts-ignore
  if (typeof piAId === 'undefined') {
    return ''
  }
  // @ts-ignore
  const cookieName = `visitor_id${piAId - 1000}`
  return getCookie(cookieName) || ''
}

const getMktoTrk = (): string => {
  const cookieName = '_mkto_trk'
  return getCookie(cookieName) || ''
}

const getPardotVisitorIdIntervalCallback = async <T = any>(
  callback: (pardotVisitorId?: string) => T | Promise<T>,
  intervalMs = 200,
  maxCount = 15
) => {
  let count = 0
  let pardotVisitorId
  while (count < maxCount) {
    count++
    pardotVisitorId = getPardotVisitorId()
    if (pardotVisitorId) {
      break
    }
    await sleep(intervalMs)
  }

  await callback(pardotVisitorId)
}

const getMktoTrkIntervalCallback = async <T = any>(
  callback: (mktoTrk?: string) => T | Promise<T>,
  intervalMs = 200,
  maxCount = 15
) => {
  let count = 0
  let mktoTrk
  while (count < maxCount) {
    count++
    mktoTrk = getMktoTrk()
    if (mktoTrk) {
      break
    }
    await sleep(intervalMs)
  }

  await callback(mktoTrk)
}

export const getTrackingId = async (): Promise<{
  pvid: string
  mktoTrk: string
}> => {
  let pvid = ''
  let mktoTrk = ''

  const pardotVisitorId = getPardotVisitorId()
  if (pardotVisitorId) {
    pvid = pardotVisitorId
  }

  const _mktoTrk = getMktoTrk()
  if (_mktoTrk) {
    mktoTrk = encodeURIComponent(_mktoTrk)
  }

  let pardotProcessing = !pardotVisitorId && isPardotTracking()
  let marketoProcessing = !mktoTrk && isMarketoTracking()

  // NOTE: Pardotのトラッキングコードは"window.load"イベント時に発火するようになっているため、非同期でvisitorIdを取得する必要がある。
  if (pardotProcessing) {
    await getPardotVisitorIdIntervalCallback((pardotVisitorId) => {
      if (pardotVisitorId) {
        pvid = pardotVisitorId
      }
      pardotProcessing = false
    })
  }

  // NOTE: 非同期でmktoTrkを取得する必要はないが、設計をシンプルにするためにPardotとロジックを統一させている。
  if (marketoProcessing) {
    await getMktoTrkIntervalCallback((_mktoTrk) => {
      if (_mktoTrk) {
        mktoTrk = encodeURIComponent(_mktoTrk)
      }
      marketoProcessing = false
    })
  }

  return {
    pvid,
    mktoTrk,
  }
}
