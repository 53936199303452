import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { rootPathState } from '@/store/rootPath'

const useUpdateRootPath = (isCustomDomain: boolean, portalId: string) => {
  const setRootPath = useSetRecoilState(rootPathState)
  useEffect(() => {
    setRootPath({
      rootPath: isCustomDomain ? '/' : `/portals/${portalId}/`,
    })
  }, [isCustomDomain, portalId, setRootPath])
}

export default useUpdateRootPath
