import { Content } from 'vidbase-types/content'

export const contentThumbnail = (content: Content | undefined) => {
  if (!content) return ''
  const activeThumbnail = content.thumbnails?.[content.thumbnails?.active]
  return activeThumbnail?.url || content.thumbnails?.default?.url
}

export const isContentThumbnailLoading = (content: Content) => {
  return (
    content.thumbnails.active === 'autoGif' &&
    content.thumbnails.autoGif?.isGenerating !== undefined &&
    content.thumbnails.autoGif?.isGenerating
  )
}

export const contentThumbnailDimensions = (content: Content) => {
  const activeThumbnail =
    content.thumbnails?.[content.thumbnails?.active] ||
    content.thumbnails?.default
  return {
    width: 480,
    height:
      (480 / (activeThumbnail?.width || 480)) *
        (activeThumbnail?.height || 270) || 270,
  }
}
