import Link from 'next/link'
import DynamicLogo from '@/components/shared/dynamicLogo'
import Logo from '@/components/shared/logo'
import useRootPath from '@/hooks/utils/useRootPath'

type Props = {
  companyLogo: string
  portalTitle: string
}

const Header = ({ companyLogo, portalTitle }: Props) => {
  const rootPath = useRootPath()
  return (
    <header className="border-b border-gray-300">
      <div className="flex items-center justify-center px-1 py-6 md:justify-start md:px-12 md:py-9">
        <Link href={rootPath}>
          {companyLogo ? (
            <DynamicLogo height={34} url={companyLogo} />
          ) : (
            <Logo width={141} height={34} />
          )}
        </Link>
        <Link
          href={rootPath}
          className="typography-p2b ml-5 overflow-hidden text-gray-800"
        >
          {portalTitle}
        </Link>
      </div>
    </header>
  )
}

export default Header
