import Link from 'next/link'
import { useRouter } from 'next/router'
import { MouseEvent, useState } from 'react'
import { defaultThemeColor } from 'vidbase-utils/vidbase/palette'
import useRootPath from '@/hooks/utils/useRootPath'

export interface MenuItemProps {
  id: string
  title: string
  primaryColor: string
}

const MenuItem = ({ id, title, primaryColor }: MenuItemProps): JSX.Element => {
  const [textColor, setTextColor] = useState(defaultThemeColor)
  const rootPath = useRootPath()
  const router = useRouter()
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const target = document.getElementById(id)
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' })
    } else {
      return router.push({ pathname: rootPath, hash: id })
    }
  }
  return (
    <Link
      href={rootPath}
      className="typography-p3 flex items-center py-4 transition-colors"
      onClick={handleClick}
      onMouseEnter={() => setTextColor(primaryColor)}
      onMouseLeave={() => setTextColor(defaultThemeColor)}
      style={{ color: textColor }}
    >
      {title}
    </Link>
  )
}

export default MenuItem
