import { Tag } from 'vidbase-types/portal/tag'
import MenuItem from '@/components/shared/sidebar/menuItem'

interface SidebarProps {
  tags: Tag[]
  primaryColor: string
}

const Sidebar = ({ tags, primaryColor }: SidebarProps) => {
  return (
    <nav className="h-full w-full border-r border-gray-300">
      <div className="py-10 pl-6">
        {tags.map((tag) => (
          <MenuItem
            key={tag.id}
            id={tag.id}
            title={tag.label}
            primaryColor={primaryColor}
          />
        ))}
      </div>
    </nav>
  )
}

export default Sidebar
