import Link from 'next/link'
import { useState } from 'react'
import Thumbnail from '@/components/shared/card/videoCard/thumbnail'
import useRootPath from '@/hooks/utils/useRootPath'

interface VideoCardProps {
  thumbnailUrl: string
  title: string
  videoId: string
  primaryColor: string
}

const defaultTextColor = '#1F2937'

const VideoCard = ({
  thumbnailUrl,
  title,
  videoId,
  primaryColor,
}: VideoCardProps) => {
  const [textColor, setTextColor] = useState(defaultTextColor)
  const rootPath = useRootPath()
  return (
    <div
      className="group flex flex-col transition-colors"
      onMouseEnter={() => setTextColor(primaryColor)}
      onMouseLeave={() => setTextColor(defaultTextColor)}
      style={{ color: textColor }}
    >
      <Link
        href={`${rootPath}videos/${videoId}`}
        className="overflow-hidden bg-black"
      >
        <div className="duration-200 ease-out group-hover:scale-110 group-hover:opacity-50">
          <Thumbnail src={thumbnailUrl} />
        </div>
      </Link>
      <Link
        href={`${rootPath}videos/${videoId}`}
        className="typography-c1m mt-2 line-clamp-2 text-lg md:text-base lg:text-sm"
      >
        {title}
      </Link>
    </div>
  )
}

export default VideoCard
