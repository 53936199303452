import { useEffect } from 'react'
import { removeTagAttributes } from '@/services/trackingCode'

const useEmbedTrackingCode = (trackingCode: string) => {
  useEffect(() => {
    if (!trackingCode) return
    const script = document.createElement('script')
    script.innerHTML = removeTagAttributes(trackingCode, 'script')
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [trackingCode])
}

export default useEmbedTrackingCode
