import { ReactNode } from 'react'
import { Tag } from 'vidbase-types/portal/tag'
import Header from '@/components/shared/header'
import Sidebar from '@/components/shared/sidebar'

interface BaseLayoutProps {
  children?: ReactNode
  companyLogo: string
  primaryColor: string
  portalTitle: string
  tags: Tag[]
}

const BaseLayout = ({
  children,
  companyLogo,
  primaryColor,
  portalTitle,
  tags,
}: BaseLayoutProps) => {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header companyLogo={companyLogo} portalTitle={portalTitle} />
      <div className="flex h-full w-full">
        <div className="hidden w-56 min-w-[14rem] md:flex">
          <Sidebar tags={tags} primaryColor={primaryColor} />
        </div>
        <div className="w-full">
          <main className="w-full px-10 py-7 md:px-20 md:py-14">
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}

export default BaseLayout
