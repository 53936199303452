import { GetServerSideProps, NextPage, InferGetServerSidePropsType } from 'next'
import { NextSeo } from 'next-seo'
import { Content, contentConverter } from 'vidbase-types/content'
import { Portal, portalConverter } from 'vidbase-types/portal'
import { Tag, tagConverter } from 'vidbase-types/portal/tag'
import { Video, videoConverter } from 'vidbase-types/portal/video'
import { publicConverter } from 'vidbase-types/public'
import { CompanyInformation } from 'vidbase-types/public/companyInformation'
import { defaultThemeColor } from 'vidbase-utils/vidbase/palette'
import BaseLayout from '@/components/shared/layouts/baseLayout'
import TopTemplate from '@/components/shared/portal/template/topTemplate'
import useEmbedTrackingCode from '@/hooks/portal/useEmbedTrackingCode'
import useUpdateRootPath from '@/hooks/utils/useUpdateRootPath'
import { adminDB } from '@/services/firebase/server'

const Page: NextPage = ({
  companyIcon,
  companyLogo,
  primaryColor,
  contents,
  portal,
  tags,
  videos,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  useUpdateRootPath(true, portal.id)
  useEmbedTrackingCode(portal.trackingCode)
  return (
    <>
      <NextSeo
        title={portal.title}
        description={portal.description}
        openGraph={{
          type: 'website',
          title: portal.title,
          description: portal.description,
          site_name: portal.title,
          images: [{ url: portal.ogpImageUrl }],
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
        nofollow={true}
        additionalLinkTags={
          companyIcon && [
            {
              rel: 'icon',
              href: companyIcon,
              keyOverride: 'favicon',
            },
          ]
        }
      />
      <BaseLayout
        companyLogo={companyLogo}
        portalTitle={portal.title}
        primaryColor={primaryColor}
        tags={tags}
      >
        <TopTemplate
          contents={contents}
          tags={tags}
          videos={videos}
          primaryColor={primaryColor}
        />
      </BaseLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const { req } = context
    const host = req.headers.host
    if (!host) {
      throw new Error('not found host')
    }

    // Fetch portal data
    const portalsRef = adminDB
      .collectionGroup('portals')
      .where('customDomain', '==', host)
      .withConverter<Portal>(portalConverter)
    const portalsSnapshot = await portalsRef.get()

    if (portalsSnapshot.docs.length === 0) {
      throw new Error('not found any portal with custom domain')
    }
    if (portalsSnapshot.docs.length > 1) {
      throw new Error('found multiple portals with custom domain')
    }

    const portalSnapshot = portalsSnapshot.docs[0]
    const { id, ref, createdAt, updatedAt, ...portal } = portalSnapshot.data()
    const portalId = id
    const workspaceId = ref.parent.parent?.id

    // Fetch companyInformation
    const companyInformationRef = adminDB
      .collection('workspaces')
      .doc(workspaceId)
      .collection('public')
      .doc('companyInformation')
    const companyInformationSnapshot = await companyInformationRef
      .withConverter<CompanyInformation>(publicConverter)
      .get()
    const companyInformation = companyInformationSnapshot.data()

    // Fetch tags
    const tagsRef = adminDB
      .collection('workspaces')
      .doc(workspaceId)
      .collection('portals')
      .doc(portalId)
      .collection('tags')
    const tagsSnapshot = await tagsRef.withConverter<Tag>(tagConverter).get()
    const tags = tagsSnapshot.docs
      .map((doc) => {
        const data = doc.data()
        const { ref, createdAt, updatedAt, ...tag } = data
        return tag
      })
      .sort((a, b) => a.order - b.order)

    // Fetch videos
    const videosRef = adminDB
      .collection('workspaces')
      .doc(workspaceId)
      .collection('portals')
      .doc(portalId)
      .collection('videos')
    const videosSnapshot = await videosRef
      .withConverter<Video>(videoConverter)
      .get()
    const videos = videosSnapshot.docs.map((doc) => {
      const data = doc.data()
      const { ref, createdAt, updatedAt, ...video } = data
      return video
    })

    // Fetch contents
    const contents = await Promise.all(
      videos.map(async (video) => {
        const contentRef = adminDB
          .collection('workspaces')
          .doc(workspaceId)
          .collection('contents')
          .doc(video.contentId)
        const contentSnapshot = await contentRef
          .withConverter<Content>(contentConverter)
          .get()

        if (!contentSnapshot.exists) {
          return new Error('not found content')
        }
        const { ref, createdAt, updatedAt, ...content } =
          contentSnapshot.data()!
        return content
      })
    )

    if (contents.some((content) => content instanceof Error)) {
      throw new Error('not found content')
    }

    return {
      props: {
        companyIcon: companyInformation?.icon || '',
        companyLogo: companyInformation?.logo || '',
        primaryColor: companyInformation?.primaryColor || defaultThemeColor,
        contents,
        portal,
        tags,
        videos,
      },
    }
  } catch (e) {
    console.error(e)
    return {
      notFound: true,
    }
  }
}

export default Page
