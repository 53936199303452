import Image from 'next/image'
import React from 'react'

interface ThumbnailProps {
  src: string
}

const Thumbnail = ({ src }: ThumbnailProps) => {
  return (
    <div className="relative h-0 w-full pb-[50%]">
      <span className="absolute inset-0 z-10 flex items-center justify-center opacity-90">
        <Image
          src="/embed_play_button.png"
          alt="embed_play_button"
          width={40}
          height={40}
        />
      </span>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className="absolute inset-0 z-0 h-full w-full object-cover"
        src={src}
        alt="thumbnail"
      />
    </div>
  )
}

export default Thumbnail
