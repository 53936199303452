import { Content } from 'vidbase-types/content'
import { Tag } from 'vidbase-types/portal/tag'
import { Video } from 'vidbase-types/portal/video'
import { contentThumbnail } from 'vidbase-utils/vidbase/content'
import VideoCard from '@/components/shared/card/videoCard'

type Props = {
  contents: Content[]
  tags: Tag[]
  videos: Video[]
  primaryColor: string
}

const TopTemplate = ({ contents, tags, videos, primaryColor }: Props) => {
  return (
    <div className="space-y-12">
      {tags.map((tag) => {
        return (
          <section key={tag.id} id={tag.id}>
            <h2 className="typography-h3 mb-8 text-gray-800">{tag.label}</h2>
            <div className="grid w-full grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {videos
                .filter((video) => video.tagIds.includes(tag.id))
                .map((video) => {
                  const content = contents.find(
                    (content) => content.id === video.contentId
                  )
                  if (!content) return
                  return (
                    <VideoCard
                      key={video.id}
                      thumbnailUrl={contentThumbnail(content)}
                      title={content.title}
                      videoId={video.id}
                      primaryColor={primaryColor}
                    />
                  )
                })}
            </div>
          </section>
        )
      })}
    </div>
  )
}

export default TopTemplate
